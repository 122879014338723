import Model, { type AsyncHasMany, attr, hasMany } from '@ember-data/model';

import { videoTypes } from 'oneday-academy-ui/data/constants';

import type PresenterModel from './presenter';
import type ReviewModel from './review';
import type StageModel from './stage';
import type SubjectModel from './subject';

export default class VideoModel extends Model {
  @attr('string') declare title: string;

  @attr('enum', {
    enumOptions: videoTypes,
    defaultValue: '',
  })
  declare type: string;

  @attr('string') declare description: string;

  @attr('string') declare excerpt: string;

  @attr('string') declare thumbnail: string;

  @attr('string') declare thumbnailAlt: string;

  @attr('string') declare sourceId: string;

  @attr('string') declare previewId: string;

  @attr('boolean', { defaultValue: false }) declare featured: boolean;

  @attr('boolean', { defaultValue: true }) declare published: boolean;

  @attr('boolean', { defaultValue: false }) declare freemium: boolean;

  @attr('boolean', { defaultValue: false }) declare premium: boolean;

  @attr('array', { defaultValue: () => [] }) declare documents: [];

  @attr('array', { defaultValue: () => [] }) declare learningLinks: [];

  @attr('number', { defaultValue: 0 }) declare reviewCount: number;

  @attr('number', { defaultValue: 0 }) declare reviewSumScore: number;

  @attr('number', { defaultValue: 0 }) declare reviewAveScore: number;

  @hasMany('stage', {
    async: true,
    inverse: 'videos',
  })
  declare stages: AsyncHasMany<StageModel>;

  @hasMany('subject', {
    async: true,
    inverse: 'videos',
  })
  declare subjects: AsyncHasMany<SubjectModel>;

  @hasMany('presenter', {
    async: true,
    inverse: 'videos',
  })
  declare presenters: AsyncHasMany<PresenterModel>;

  @hasMany('review', {
    async: true,
    inverse: 'video',
  })
  declare reviews: AsyncHasMany<ReviewModel>;

  get heading1() {
    return this.title;
  }

  get heading2() {
    return this.description;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    video: VideoModel;
  }
}
