import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'ember-cloud-firestore-adapter/firebase/storage';
import { v4 as uuid } from 'uuid';

export default class FormsImageUploadComponent extends Component {
  @tracked existingPath;

  @tracked uploadedPath;

  @tracked uploading = false;

  @tracked progress = 0;

  constructor(owner, args) {
    super(owner, args);

    if (this.args.filePath && this.args.filePath.length > 0) {
      this.existingPath = this.args.filePath;
    }
  }

  get storageSegment() {
    return this.args.public ? 'public/images/' : 'images/';
  }

  @action
  onFileSelect(evt) {
    this.uploading = true;

    const file = evt.target.files[0];
    const storage = getStorage();
    const storageRef = ref(storage, this.storageSegment + `${uuid()}-${file.name}`);

    evt.stopPropagation();
    evt.preventDefault();

    const metadata = {
      contentType: file.type,
      customMetadata: {
        originalName: file.name,
      },
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + this.progress + '% done');

        // switch (snapshot.state) {
        //   case 'paused':
        //     console.log('Upload is paused');

        //     break;
        //   case 'running':
        //     console.log('Upload is running');

        //     break;
        // }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((/*downloadURL*/) => {
          // console.log('Image available at', downloadURL);
          this.uploadedPath = uploadTask.snapshot.metadata.fullPath;
          this.existingPath = uploadTask.snapshot.metadata.fullPath;
          // TODO: implement progress bar
          this.uploading = false;

          if (this.args.onChange) {
            this.args.onChange(this.uploadedPath);
          }
        });
      }
    );
  }
}
