import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormField from '@mysolomon/solomon-ui-addon/src/utils/form-field';
import { sortBy } from 'lodash-es';

import { videoTypes } from '../../../data/constants';

export default class FormsVideoComponent extends Component {
  @service store;

  fieldSet = {
    title: new FormField({
      key: 'title',
      value: this.args.model.title,
      validators: ['required'],
    }),
    type: new FormField({
      key: 'type',
      value: this.args.model.type,
      validators: ['required'],
    }),
    description: new FormField({
      key: 'description',
      value: this.args.model.description,
      validators: ['required'],
    }),
    excerpt: new FormField({
      key: 'excerpt',
      value: this.args.model.excerpt,
      validators: ['required', 'maxLength'],
      validationOptions: {
        maxLength: 160,
      },
    }),
    sourceId: new FormField({
      key: 'sourceId',
      value: this.args.model.sourceId,
      validators: ['required'],
    }),
    thumbnailAlt: new FormField({
      key: 'thumbnailAlt',
      value: this.args.model.thumbnailAlt,
      validators: ['required'],
    }),
    previewId: new FormField({
      key: 'previewId',
      value: this.args.model.previewId,
    }),
    subjects: new FormField({
      key: 'subjects',
      value: this.args.model.subjects,
      validators: ['required'],
    }),
    stages: new FormField({
      key: 'stages',
      value: this.args.model.stages,
      validators: ['required'],
    }),
    presenters: new FormField({
      key: 'presenters',
      value: this.args.model.presenters,
      validators: ['required'],
    }),
  };

  get videoTypeOptions() {
    return sortBy(videoTypes);
  }

  @action
  setProperty(target, property, value) {
    target[property] = value;
    this.fieldSet[property].value = value;
  }

  @action
  thumbnailChange(model, thumbUrl) {
    model.thumbnail = thumbUrl;
  }

  @action
  removeFile(model, filePath) {
    model.documents = [...model.documents.filter((d) => d !== filePath)];
  }

  @action
  clearFiles(model) {
    model.documents = [];
  }

  @action
  addFile(model, filePath) {
    model.documents = [...model.documents, filePath];
  }

  @action
  onAddLearningLink(model, item) {
    model.learningLinks = [...model.learningLinks, item];
  }

  @action
  onRemoveLearningLink(model, item) {
    model.learningLinks = [...model.learningLinks.filter((i) => i !== item)];
  }

  @action
  onChangeVideoType(selected) {
    this.args.model.type = selected;
  }
}
